.session-popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #000000C2;
    z-index: 55;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-timeout-card {
    max-width: 600px;
    /* width: 500px; */
    background-color: #F6F6F6;
    padding: 50px;
    border-radius: 20px;
    margin: 2px 20px;
}

.session-image {
    width: 150px;
}

.session-heading {
    color: #1A3D6F;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding: 15px 0;
}

@media screen and (max-width: 700px) {
    .session-timeout-card {
        min-width: 300px;
        padding: 25px;
    }
    .session-heading {
        font-size: 20px;
        font-weight: 600;

    }
}

