.custom-typeahead{
    margin: 5px 3px;
 
.rbt .rbt-input-main::-ms-clear {
    display: none;
  }
  
  /**
   * Menu
   */
  .rbt-menu {
    margin-bottom: 2px;
  }
  .rbt-menu > .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rbt-menu > .dropdown-item:focus {
    outline: none;
  }
  .rbt-menu-pagination-option {
    text-align: center;
  }
  
  /**
   * Multi-select Input
   */
  .rbt-input-multi {
    cursor: text;
    // overflow: scroll;
    // position: relative;
    overflow-y: auto;
    // max-height: 38px;
    align-items: start;
    vertical-align: top;
    border-radius: 8px;
    
  }
  .rbt-input-multi.focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    color: #495057;
    outline: 0;
  }
  .rbt-input-multi.disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  .rbt-input-multi.is-invalid.focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  .rbt-input-multi.is-valid.focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .rbt-input-multi input::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
    font-size: 13px;
  }
  .rbt-input-multi input:-ms-input-placeholder {
    color: #6c757d;
    font-size: 13px;
  }
  .rbt-input-multi input::-webkit-input-placeholder {
    color: #6c757d;
    font-size: 13px;
  }
  .rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-top: -4px;
    overflow: hidden;
  }
  .rbt-input-multi .rbt-input-main {
    margin: 5px 3px;
  }
  
  /**
   * Close Button
   */
  .rbt-close {
    z-index: 1;
  }
  .rbt-close-lg {
    font-size: 1.5rem;
  }
  
  /**
   * Token
   */
  .rbt-token {
    background-color: #e7f4ff;
    border: 0;
    border-radius: 0.25rem;
    color: #007bff;
    display: inline-flex;
    line-height: 1rem;
    margin: 5px 3px 2px 0;
    font-size: 13px;
    text-align: left;
    word-break: break-all;
  }
  .rbt-token .rbt-token-label {
    padding: 0.2rem 0.1rem 0.2rem 0.4rem;
  }
  .rbt-token .rbt-token-label:not(:last-child) {
    padding-right: 0.2rem 0.1rem 0.2rem 0.4rem;
  }
  .rbt-token-disabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: #495057;
    pointer-events: none;
  }
  .rbt-token-removeable {
    cursor: pointer;
  }
  .rbt-token-active {
    background-color: #007bff;
    color: #fff;
    outline: none;
    text-decoration: none;
    font-size: 13px;

  }
  .rbt-token .rbt-token-remove-button {
    background-image: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: none;
    color: inherit;
    display: flex;
    justify-content: center;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 0.25rem 0.5rem;
    padding-left: 0;
    text-shadow: none;
  }
  .rbt-token .rbt-token-remove-button .rbt-close-content {
    display: block;
  }
  
  /**
   * Loader + CloseButton container
   */
  .rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none; /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
  }
  .rbt-aux-lg {
    width: 3rem;
  }
  .rbt-aux .rbt-close {
    margin-top: -0.25rem;
    pointer-events: auto; /* Override pointer-events: none; above */
  }
  
  .has-aux .form-control {
    padding-right: 2rem;
  }
  .has-aux .form-control.is-valid, .has-aux .form-control.is-invalid {
    background-position: right 2rem center;
    padding-right: 4rem;
  }
  
  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
  }
  
  /**
   * Input Groups
   */
  .input-group > .rbt {
    flex: 1;
  }
  .input-group > .rbt .rbt-input-hint, .input-group > .rbt .rbt-aux {
    z-index: 5;
  }
  .input-group > .rbt:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .rbt:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
} 