.leaderCardimg{
    width: 60px; 
    /* background: white; */
    background-blend-mode: color-burn !important; 
    /* border-radius:25px ; */
}
.cardmainBack{
    background: transparent !important;
}
.LeaderBoard .rdt_TableBody {
  padding: 0px !important; 
}
.table .rdt_TableRow:hover { 
  transform: scale(1.05); /* Slight scaling on hover */
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07); 
  background: #d8d8d8cc !important;
  /* transition: transform 0.5s ease, box-shadow 0.3s ease;  */
}
  .table > :not(caption) > * > * {
  padding: 0 !important; /* Force removal of padding */ 
}
 

@media only screen and (max-width:471px){
  .filtersbtngroupofleader{
    width: 100%;
    flex-direction: column;
    align-items: center !important;
     justify-content: center !important; 
  }
  .filtersbtngroupofleader div:nth-child(2){
    margin-block: 5px;
  }
  .filtersbtngroupofleader .applyfilterbtn{
    margin-left: 2px !important;
  }
  .filtersbtngroupofleader div{
    margin-inline: 0px !important;
  }
}

  