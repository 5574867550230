.new_ticket_card_details{
    color:#555555D9;
    font-size: '14px';  
  
}
.custom-modal .modal-content {
    border-radius: 24px;  /* Change this value to adjust border radius */
  }
.formGrupCustom{
    padding-block: 10px;
}
.subHeading{
    color: #555555;
    font-size: 16px;
    font-weight: 400;
}
.selectallbtn{
    border: 1px solid #175FA4;
    border-radius: 100px;
    padding: 4px 8px;
    background: transparent;
    color: #175FA4D9;
}
.clearallbtn{
    border: 1px solid #DFDFDF;
    border-radius: 100px;
    padding: 4px 8px;
    background: transparent;
    color: #353535D9;
}