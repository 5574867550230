.com-red-color {
    color: #ff0000;
}
.com-yellow-color {
    color: rgb(249 197 10) !important;
}
.com-green-color {
    color: #20e647;
}

.bg-green-color {
    background-color: #20e647;
}
.bg-yellow-color {
    background-color: rgb(249 197 10)
}
.bg-red-color {
    background-color: #ff0000;
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}

@media (min-width: 1536px) {
    .row-cols-2xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-2xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-2xl-3 > * {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .row-cols-2xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-2xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-2xl-6 > * {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }



