.custom_camera_card {
    visibility: visible; // Default visibility
}

.camera_image {
    width: 150px;
    height: 110px;
    border-radius: 10px;
  }
  


.camera_card_body{
    padding: 20px 10px 15px 10px;
}

.camera_details{
    color: #9b9b9b;
}

.camera_list_card {
    min-width: 250px !important;
    max-height: 200px !important;
  }

.view_icon{
    display: none;
    cursor: pointer;
}

@media(max-width:300px){
.camera_list_card{
    width: 200px !important;
    min-width: 200px !important;
}
}

@media (max-width: 590px) {
    .custom_camera_card {
        display: none; 
    }
    .custom-col-9 {
        width: 100% !important; 
        flex: 0 0 100%; 
        max-width: 100%; 
    }
    .view_icon{
        display: inline-block;
        float:right;
    }
}

@media(max-width:560px){
.camera_list_card{
    width: 280px !important;
    min-width: 280px !important;
}
}

@media (max-width:577px) {
.camera_image {
    width: 90px !important; 
    }
    
.camera_list_card {
    min-height: 10px !important;
    width: 280px;
    min-width: 200px !important;
    height: 150px !important;
    padding: 0px !important;
}
    }

@media(max-width:991px) {
.camera_list_card {
    min-height: 10px !important;
    height: 130px !important;
    padding: 0px !important;
}
}

@media (max-width: 1100px) and (min-width: 993px) {
    .camera_image {
        // width: 90px !important;
        width: 134px !important;
    }
    
    }

@media (max-width: 1120px) and (min-width: 993px) {
    .custom-col-card {
        width: 100% !important; 
        flex: 0 0 100%; 
        max-width: 100%; 
    }
    
    }

@media (max-width: 898px) and (min-width: 590px) {
        .custom-col-card {
            width: 100% !important; 
            flex: 0 0 100%; 
            max-width: 100%; 
        }
        .camera_image {
            width: 170px !important;
            height: auto;
        }
        
        }

@media (max-width:1170px) {
.camera_image {
    width: 150px; 
    }

    .camera_list_card {
        min-width: 400px !important;
        min-height: 250px !important;
        }
}

@media (max-width:1200px) {

.camera_list_card {
    // width: 300px !important;
    min-width: 400px !important;
    min-height: 200px !important;
    // height: 200px !important;
    // padding: 0px !important;
    margin: 10px 0px;
}
}

// @media (min-width: 1200px) and (max-width: 1200px) {
//     .camera_list_card {
//         height: 150px !important; // Ensure consistent height at 1200px
//         min-height: 150px !important;
//         padding: 10px !important;
//         width: 400px !important; // Ensure consistent width
//         background-color: lightblue; // Example background to differentiate
//     }
// }

@media (max-width: 680px) {
.camera_image {
    width: 110px !important;
    height: 80px; 
}
}


.camera_main_card {
    // overflow-y: auto;
    // max-height: 400px; // Adjust to control the scrollable area
  
    // Optional scrollbar customization
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    @media (min-width: 1200px) {
        overflow-y: auto; // Make the first column scrollable on large screens
        // max-height: 400px; // Control the max height for the scroll area on large screens
      }
    
      // On smaller screens, no fixed height or scroll
      @media (max-width: 1199px) {
        max-height: none; // Allow flexible height
        overflow-y: unset; // Remove scroll behavior
      }
  }