.report_card:hover{
  background-color: rgb(231, 231, 231);
}
.filter-card {
  width: 200px;
  border: 1px solid grey;
  border-radius:  0 0 6px 6px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  animation: slideFadeIn 0.5s ease-out forwards;
}
.filter-btnn {
  width: 150px;
  height: 44px;
  transition: 1s all;
  background-color: #1e67d6;
  border-radius: 6px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

}
.filter-btnn.border_R {
  border-radius: 6px 6px 0 0;
  width: 200px;
}




/* Keyframes for slide and fade in */
@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Slide down effect from slightly above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Final position */
  }
}