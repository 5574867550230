@media (max-width:960px){
    .modelPageImage{
        height: 30vh !important;
    }
}
.custom-scrollbarrr::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  border-radius: 20px; /* Round the edges of the track */

}

.custom-scrollbarrr::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 20px; /* Round the edges of the track */
}

.custom-scrollbarrr::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 20px; /* Round the edges of the scrollbar thumb */
}

.custom-scrollbarrr::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}
.cardtable{
  background-color: transparent !important;
}
.ellipsis-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease; 
}

.ellipsis-text:hover,
.ellipsis-text:focus {
  white-space: normal;
  overflow: visible;
}

/* Optional: For Firefox */
.custom-scrollbarrr {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  border-radius: 20px; /* Round the edges of the track */

  scrollbar-color: #888 #f1f1f1; /* Scrollbar thumb color and track color */
}