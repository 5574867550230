@import './assets/scss/app.scss';

@media (min-width: 1300px) {
    // 2xl grid columns for 12-column layout
  
    .col-2xxl {
      flex: 1 0 0%;
      max-width: 100%;
    }
  
    .col-2xxl-auto {
      flex: 0 0 auto;
      width: auto;
    }
  
    .col-2xxl-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
  
    .col-2xxl-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  
    .col-2xxl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .col-2xxl-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  
    .col-2xxl-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
  
    .col-2xxl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .col-2xxl-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
  
    .col-2xxl-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  
    .col-2xxl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .col-2xxl-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
  
    .col-2xxl-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
  
    .col-2xxl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }