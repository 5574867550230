.area-cardd {
height: 417px !important;
}
.area-carddd {
    height: 440px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.area-card-bar{
    height: 428px !important;
}

.modal-style {
    width: 900px !important;

}
