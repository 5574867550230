.filter-row {
 text-align: end;
 display: flex;
 justify-content: end;
 align-items: start;
 flex-wrap: wrap;
}

@media (max-width: 700px) {
   .filter-row{
    text-align:left ;
    justify-content: start;
    align-items: start;
   }
  }