.search-main-div {
    position: relative;
}

.input-type-search {
    background-color: #ffffff;
    border-radius: 16px;
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    padding: 0 20px 0 45px;
    
}

.it-search-icon {
    position: absolute;
    top: 37px;
    left: 27px;
    width: 16px;
    
}

.it-button {
    color: white;
    background-color: #1C3664;
    height: 50px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 2px 12px;
    transition: all 0.4ms;
    width: 126px;
    justify-content: center;
    font-size: 17px;
}

.it-icon {
    width: 18px;
    margin-top: 3px;
}


.it-button:hover {
   background-color: #23519f;
} 
.marginBottom {
    margin-bottom: 0 !important;
}
@media screen and (max-width: 1200px) {
    .marginBottom {
        margin-bottom: 20px !important;
    }
}