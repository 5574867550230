.add-user-form-page {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 11;
    background-color: #000000C2;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.add-user-form {
    background-color: #fff;
    max-width: 1200px;
    /* padding: 20px 30px; */
    width: 80%;
    border-radius: 10px;
}
.add-user-top {
   border-bottom:1px solid #EFF4FA; 
   padding: 10px 20px;
}

.inputs-sec{
    padding: 20px 40px;
}

.module-permission {
    width: 100%;
    padding: 20px 40px;
    background-color: #EFF4FA;
    
   
}