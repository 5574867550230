.it-support-page {
    padding-top: 200px;
}
.rdt_TableCol_Sortable {
    justify-content: center !important;
}
.rdt_TableCell {
    justify-content: center !important;
}

