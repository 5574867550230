.support-ticket-hover:hover {
    scale: 1.02;
    transition: 0.3s;
    cursor: pointer;
}

.backArrow {
    font-size: 25px;
    color: black;
}

.backbtnparent {
    border: 0px;
    background: transparent;
    outline: none;
    padding: 0px !important;
    border: 1px solid transparent;
}

.backbtnparent:hover {
    scale: 1.1;
}

.custom-select {
    position: relative;
}

.previousCardStyle ul li {
    line-height: 30px;
    padding-left: 10px;
    color: grey;
}

.previousCardStyle ul li a {
    color: #1e67d6 !important;
    cursor: pointer;
    font-style: oblique;
    font-size: 10px;
}

.previousCardQuery {
    display: flex;
    /* justify-content: center;  */
    align-items: start;
    flex-direction: column;
    height: 100%;
}

.previousCardQuery label {
    color: grey;
}

.textArea {
    border-radius: 5px;
    border: 0px;
    width: 100%;
    overflow: auto;
    color: grey; 
    height: 70% !important;
    /* padding: 15px; */
    margin-top: 5px;
}

.PreviousCard {
    overflow-x: hidden;
    overflow-y: auto;
}
.selectAllbtnforTicket{
    background: white !important;
    color: #1E67D6;
}
.selectAllbtnforTicket:hover{
    background: #1E67D6 !important;
    color: white;
}
.cancelAllbtnforTicket{
    background: white !important;
    color: grey;
    border: 1px solid grey !important;
}
.cancelAllbtnforTicket:hover{
    background: grey !important;
    color: white;
}
 
.button {
    text-decoration: none;
    /* line-height: 1; */
    /* border-radius: 1.5rem; */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 10px 10px 20px rgba(0,0,0,.05);
    background-color: #fff;
    color: #121212;
    border: 1px solid #1E67D6;
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .button-decor {
    position: absolute;
    inset: 0;
    background-color: var(--clr);
    transform: translateX(-100%);
    transition: transform .3s;
    z-index: 0;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    font-weight: 600;
    position: relative;
    overflow: hidden;
  }
  
  .button__icon {
    font-size: 20px;
    background-color: var(--clr);
    /* color: #00ad54 !important; */
    display: grid;
    place-items: center;
    margin-right: 10px;
    border-radius: 15px;
    padding: 5px;
  }
  
  .button__text {
    display: inline-block;
    transition: color .2s;
    /* padding: 2px 1.5rem 2px; */
    /* padding-left: .75rem; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
  
  .button:hover .button__text {
    color: #fff;
  }
  
  .button:hover .button-decor {
    transform: translate(0);
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
  
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

@media only screen and (max-width: 1200px) {
    .previousCardStyle ul li {
        padding-left: 0px;
        margin-bottom: 5px;
    }
}

@media only screen and (min-width:992px) and (max-width:1010px){
    .generatecards{
        height: 270px;
    }
}