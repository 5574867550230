.add-alert {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.add-alert-form {
  max-width: 1000px;
}
.Add-alert-head {
  text-align: start !important;
}
.border-line-container {
  padding: 5px 0;
}
.border-line {
  border: 1px solid #dee2e6;
  /* padding: 5px 10px; */
}
.type-head .rbt-input-multi {
  outline: none;
  border: 0;
  background-color: transparent;
  padding: 10px 20px;
  border: 1px solid #dee2e6;
  height: 64px;
  border-radius: 16px;
}

.bgg-color {
  background-color: #f7f8f9;
}
.switch-input {
  border: 1px solid grey !important;
}

.camera_iamges {
  width: 100%;
  height: 200px;
}
.single_image {
  width: 100%;
  height: 200px;
  object-fit: fill;
  border-radius: 5px;
}
