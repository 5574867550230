.showDate{
    position: absolute; 
    right: 50px;
}

@media(max-width:767px){
    .showDate{
        right: 0px;
        left: 10px;
    }
}
/* .input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
    border: 2px solid red;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    border: none;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */
